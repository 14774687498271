var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("v-select", {
                class: { noBorders: _vm.readOnly },
                attrs: {
                  "hide-details": "auto",
                  label: "Rol",
                  "persistent-placeholder": "",
                  items: _setup.partyRoles,
                  "item-text": "description",
                  "item-value": "key",
                  "return-object": "",
                  outlined: !_vm.readOnly,
                  readonly: _vm.readOnly,
                },
                model: {
                  value: _setup.role,
                  callback: function ($$v) {
                    _setup.role = $$v
                  },
                  expression: "role",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-autocomplete", {
                ref: "selector",
                class: { noBorders: _vm.readOnly },
                attrs: {
                  items: _setup.userItems,
                  "search-input": _setup.searchUsers,
                  label: "Gebruiker",
                  "hide-details": "auto",
                  "hide-no-data": "",
                  loading: _setup.usersLoading,
                  "item-text": "name",
                  "item-value": "id",
                  placeholder: "Zoek actieve gebruiker...",
                  "persistent-placeholder": "",
                  "return-object": "",
                  "prepend-inner-icon": "mdi-magnify",
                  clearable: "",
                  "loader-height": "4",
                  outlined: !_vm.readOnly,
                  readonly: _vm.readOnly,
                  "menu-props": _setup.menuProps,
                },
                on: {
                  "update:searchInput": function ($event) {
                    _setup.searchUsers = $event
                  },
                  "update:search-input": function ($event) {
                    _setup.searchUsers = $event
                  },
                  click: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "clear",
                        undefined,
                        $event.key,
                        undefined
                      )
                    )
                      return null
                    return _setup.onClear.apply(null, arguments)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c("v-list-item-subtitle", [
                              _vm._v(_vm._s(item.description)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _setup.selectedUser,
                  callback: function ($$v) {
                    _setup.selectedUser = $$v
                  },
                  expression: "selectedUser",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3", align: "center" } },
            [
              !_vm.readOnly
                ? _c(
                    "v-btn",
                    {
                      staticClass: "primary mt-3",
                      attrs: {
                        disabled: _setup.addDisabled,
                        dark: !_setup.addDisabled,
                      },
                      on: { click: _setup.addContact },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "", center: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }